import { http } from "@/core/ApiConfig";
import { DiagnosticoRespostaValor } from "@/core/models/geral";
import { Service } from "../shared/Service";
import { DiagnostivoRespostaValorModel } from "@/core/models/model/DiagnostivoRespostaValorModel";

export class DiagnosticoRespostaService extends Service {

    constructor() {
        super('v1/diagnosticoResposta');
    }

    private GetFiltro(parametros: string, filter: any){
      if (filter) {
        let paramTemp = '';
  
        if (filter.diagnosticoId){
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `diagnosticoId eq ${filter.diagnosticoId}`;
        }

        if (filter.dataHoraInicial){
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `envio/dataHora ge ${filter.dataHoraInicial}`;
        }

        if (filter.dataHoraFinal){
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `envio/dataHora le ${filter.dataHoraFinal}`;
        }

        // if (filter.avaliador){
        //   if (paramTemp) { paramTemp += ' and '; }
        //   paramTemp += `Contains(avaliador, '${filter.avaliador}')`;
        // }

        // if (filter.avaliadorId){
        //   if (paramTemp) { paramTemp += ' and '; }
        //   paramTemp += `avaliadorId eq ${filter.avaliadorId}`;
        // }

        // if (filter.avaliadorId){
        //   if (paramTemp) { paramTemp += ' and '; }
        //   paramTemp += `avaliadorId eq ${filter.avaliadorId}`;
        // }

        if (filter.organizacao && filter.organizacao != "TODAS"){
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `Contains(ong, '${filter.organizacao}')`;
        }

        if (true){
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `situacaoId ne 3`;
        }
  
        if (paramTemp) {
  
          if(parametros){
            parametros += '&';
          }
          else {
            parametros += '?';
          }
  
          parametros += `$filter=${paramTemp}`;
        }  
      }  
      return parametros;
    }

    ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string){    
      let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);  
      parametros = this.GetFiltro(parametros, filter);  
      return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }

    public ListarRespostaOng(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter?: any){
      let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);    
      parametros = this.GetFiltro(parametros, filter);        
      return http.get(`${this.GetNomeControle()}/ListarRespostaOng${parametros}`);
    }

    Editar(id: number){
      return http.get(`${this.GetNomeControle()}/${id}/Editar`);
    }

    AbrirResposta(diagnosticoId: number){
      return http.get(`${this.GetNomeControle()}/${diagnosticoId}/AbrirResposta`);
    }

    AbrirRespostaAvaliador(diagnosticoId: number, ongId: number, ong: string){
      return http.get(`${this.GetNomeControle()}/${diagnosticoId}/AbrirRespostaAvaliador`, {
        params: {
          ongId,
          ong
        }
      });
    }

    ObterRespostaCampoDiagnostico(id: number, campoId: number, ongId: number, diagnosticoId: number, avaliadorId?: number){
      return http.get(`${this.GetNomeControle()}/${id}/Resposta/${campoId}`, {
        params:{
          ongId,
          diagnosticoId,
          avaliadorId
        }
      });
    }

    ExisteAbertoOrganizacao(id: number){
      return http.get(`${this.GetNomeControle()}/${id}/ExisteAbertoOrganizacao`);
    }

    Responder(model: DiagnostivoRespostaValorModel){
      return http.post(`${this.GetNomeControle()}/Responder`, model);
    }

    Enviar(id: number, dataCompetencia: string){
      return http.post(`${this.GetNomeControle()}/Enviar/${id}`, undefined, {
        params:{
          dataCompetencia
        }
      });
    }

    Cancelar(id: number){
      return http.post(`${this.GetNomeControle()}/${id}/Cancelar`);
    }

    DetalharDiagnosticoResposta(id: number, diagnosticoId: number){
      return http.get(`${this.GetNomeControle()}/DetalharDiagnosticoResposta`, {
        params: {
          id,
          diagnosticoId
        }
      });
    }

    AvaliadoresDiagnostico(diagnosticoId: number){
      return http.get(`${this.GetNomeControle()}/AvaliadoresDiagnostico/${diagnosticoId}`);
    }

    OngsPorResposta(diagnosticoId: number){
      return http.get(`${this.GetNomeControle()}/OngsPorResposta/${diagnosticoId}`);
    }

    DiagnosticosRespondidosOng(avaliadorId: number){
      return http.get(`${this.GetNomeControle()}/RespondidosOng`, {
        params: {
          avaliadorId
        }
      });
    }

    GraficoAranhaTodos(tipoAvaliacaoId: number, avaliadorId: number, diagnosticoId: number, dataHoraInicio?: string, dataHoraFim?: string){
      return http.get(`${this.GetNomeControle()}/GraficoAranhaTodos`, {
        params: {
          tipoAvaliacaoId,
          avaliadorId,
          diagnosticoId,          
          dataHoraInicio,
          dataHoraFim
        }
      });
    }

    GraficoAranha(tipoAvaliacaoId: number, avaliadorId: number, diagnosticoId: number, organizacaoId: number, dataHoraInicio?: string, dataHoraFim?: string){
      return http.get(`${this.GetNomeControle()}/GraficoAranha`, {
        params: {
          tipoAvaliacaoId,
          avaliadorId,
          diagnosticoId,
          organizacaoId,
          dataHoraInicio,
          dataHoraFim
        }
      });
    }

    RelatorioDiagnostico(tipoAvaliacaoId: number,avaliadorId: number, diagnosticoId: number, organizacaoId: number, dataHoraInicio?: string, dataHoraFim?: string){
      return http.get(`${this.GetNomeControle()}/RelatorioDiagnostico`, {
        params: {
          tipoAvaliacaoId,
          avaliadorId,
          diagnosticoId,
          organizacaoId,
          dataHoraInicio,
          dataHoraFim
        }
      });
    }

    Quantitativo(){
      return http.get(`${this.GetNomeControle()}/Quantitativo`);
    }
}